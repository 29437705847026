<template>
  <div class="row position-relative">
    <div class="col-xxl-10 col-xl-9 col-lg-8 order-1 order-lg-0">
      <div class="widget position-relative">
        <div class="widget-heading -list">
          <h1 class="textStyle">
            {{ title ? title : "" }}
            <FontAwesomeIcon
              v-if="isHelpVisible()"
              v-tooltip="{
                title: $t('admin.help.' + $route.params.alias),
              }"
              class="ms-2 helpDocument"
              icon="fa-light fa-circle-question"
            />
          </h1>
        </div>
        <div class="widget-content list">
          <div v-if="(data && data.length) || loading">
            <SortTableComponent
              :actions="actions"
              :apiFilters="false"
              :columns="columns"
              :datas="data"
              :loading="loading"
              :maxResult="maxResult"
              @refresh="refresh"
              @remove="remove"
              @apiCall="apiCall"
            />
          </div>
          <div v-else class="text-center">
            <div class="noResult blockStyle">
              {{ $t("No data available") }}<br />
              {{ $t("Please click on the add button") }}
            </div>
            <router-link
              v-if="this.alias"
              :to="{
                name: $route.meta.addPath ? $route.meta.addPath : 'adminAdd',
                params: { alias: alias },
              }"
              class="btn btn-primary mb-3"
              >{{ add ? add : $t("admin.add") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-xxl-2 col-xl-3 col-lg-4 accordion order-lg-1 order-0"
      id="accordionAction"
    >
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingAction">
          <button
            :disabled="windowWidth > 991"
            :class="
              'accordion-button ' + (windowWidth < 991 ? 'collapsed' : '')
            "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseAction"
            aria-expanded="true"
            aria-controls="collapseAction"
          >
            {{ $t("actions") }}
          </button>
        </h2>
        <div
          id="collapseAction"
          :class="
            'accordion-collapse collapse ' + (windowWidth > 991 ? 'show' : '')
          "
          aria-labelledby="headingAction"
          data-bs-parent="#accordionAction"
        >
          <div class="accordion-body">
            <router-link
              v-if="this.alias"
              :to="{
                name: $route.meta.addPath ? $route.meta.addPath : 'adminAdd',
                params: { alias: alias },
              }"
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-plus" />
              {{ add ? add : $t("admin.add") }}
            </router-link>
            <router-link
              v-if="alias === 'help-document'"
              :to="{
                name: 'adminHelpDocumentSettings',
                params: { alias: 'help-document' },
                query: { helpDocument: 1, time: Date.now() },
              }"
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-gear" />
              {{ $t("admin.help_settings") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import { mapState } from "pinia";
import { stringManager } from "@/services/utilities/string-manager";
import { adminManager } from "@/services/admin/admin-manager";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { accessManager } from "@/services/security/access-manager";
import { apiConnection } from "@/services/api-connection";
import { notificationManager } from "@/services/utilities/notification-manager";
import { useAdminStore } from "@/store/admin/admin";

export default {
  name: "ListView",
  components: {
    SortTableComponent,
  },
  data() {
    return {
      data: [],
      columns: [],
      loading: false,
      alias: "",
      current: "",
      listParams: [],
      actions: [],
      title: "",
      add: "",
      generic: true,
      tradPrefix: "",
      tradGender: 1,
      windowWidth: window.innerWidth,
      maxResult: 0,
    };
  },
  computed: {
    ...mapState(useAdminStore, {
      menu: (store) => store.menu,
    }),
  },
  watch: {
    menu() {
      if (this.menu && Object.keys(this.menu).length) {
        this.getCurrentMenuEntry();
        this.init();
      }
    },
    windowWidth() {
      this.updateWindowWidth();
    },
  },
  beforeRouteEnter(to, from, next) {
    accessManager.checkAccessByPermission(to, from, next);
  },
  mounted() {
    if (Object.keys(this.menu).length) {
      this.getCurrentMenuEntry();
    }
    this.init();
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    remove(row) {
      adminManager.remove(this.alias, row.row.id).then(() => {
        this.init();
      });
    },
    refresh(listParams) {
      this.listParams = {
        search: listParams.search,
        sortByField: listParams.sortByField,
        sortOrder: listParams.sortOrder,
        limit: listParams.limit,
        offset: listParams.offset,
      };
      this.init();
    },

    apiCall(action) {
      if (action.action.method) {
        switch (action.action.method) {
          case "put":
            apiConnection.put(action.action.route + action.row.id).then(() => {
              notificationManager.showNotification(
                "success",
                this.$t(
                  action.action.translation.replace(".title", ".success"),
                ),
              );
              this.init();
            });
            break;
          case "delete":
            apiConnection
              .delete(action.action.route + action.row.id)
              .then((data) => {
                this.$t(data.action.translation.replace(".title", ".delete"));
                this.init();
              });
            break;
          case "get":
            apiConnection
              .get(action.action.route + action.row.id)
              .then((data) => {
                this.$t(data.action.translation);
                this.init();
              });
            break;
        }
      }
    },

    initActions() {
      this.actions = [
        {
          translation: "admin.modify",
          translationParams: { name: "name", id: "id" },
          icon: "pen",
          type: "success",
          actionType: "router",
          action: this.$route.meta.editPath
            ? this.$route.meta.editPath
            : "adminEdit",
          cfaStyle: false,
          alias: this.alias,
        },
        {
          translation: "admin.delete",
          translationParams: { name: "name", id: "id" },
          icon: "trash",
          type: "danger",
          action: "remove",
          cfaStyle: false,
        },
      ];
    },
    init() {
      this.alias = this.$route.params.alias;
      this.tradPrefix = stringManager.toCamelCase(this.alias);
      this.initActions();
      this.loading = true;
      this.tradGender = stringManager.gender(this.alias);
      this.title = this.$tc("global.list.title", this.tradGender, {
        name: this.$tc("admin." + this.tradPrefix + ".title", 2).toLowerCase(),
      });
      this.add = this.$tc("global.add.title", this.tradGender, {
        name: this.$tc("admin." + this.tradPrefix + ".title", 1).toLowerCase(),
      });
      this.$route.meta.title = this.title;

      if (
        this.current &&
        this.current.params.actions &&
        this.current.params.actions.length
      ) {
        let actions = [];
        Object.values(this.current.params.actions).forEach((action) => {
          actions.push({
            translation: action.label + ".title",
            icon: action.icon,
            action: action.action,
            type: action.type,
            cfaStyle: false,
            route: action.route,
            method: action.method,
            value: action.value,
            field: action.field,
          });
        });

        this.actions = actions.concat(this.actions);
      }

      if (this.alias === "template") {
        this.generic = false;
      }

      if (this.alias === "document") {
        this.listParams = { search: "&documentType=1" };
      }

      adminManager
        .list(this.alias, this.generic, this.listParams)
        .then((data) => {
          this.data = data;
          if (data && data.length) {
            this.columns = sortableTableManager.getColumns(
              this.data[0],
              "admin",
            );
            if (data.count) {
              this.maxResult = data.count;
            } else {
              this.maxResult = data.length;
            }
          }
          this.loading = false;
        });
    },

    getCurrentMenuEntry() {
      this.alias = this.$route.params.alias;
      let current = null;
      Object.values(this.menu).forEach((item) => {
        if (!current && Object.keys(item.children).length) {
          current = Object.values(item.children.items).find(
            (item) => item.params && item.params.alias === this.alias,
          );
        }
      });
      this.current = current;
    },

    isHelpVisible() {
      return adminManager.ADMIN_ALIAS_TO_DISPLAY_HELP.includes(
        this.$route.params.alias,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-content {
  &.list {
    min-height: 175px;
    padding: 20px;
  }
}
</style>
