import { apiConnection } from "@/services/api-connection";
import { notificationManager } from "@/services/utilities/notification-manager";
import { formValidator } from "@/services/form/form-validator";
import router from "@/router";
import { i18n } from "@/i18n";

class FormManager {
  processForm(models, search = false, id = null) {
    let result = {};
    if (Object.keys(models).length) {
      Object.values(models).forEach((field) => {
        result = this.processField(field, result, models, search);
      });
    }
    if (id) {
      result = { ...result, id: parseInt(id) };
    }
    return result;
  }

  getQuery(params) {
    let query = "";
    let index = 0;
    if (params) {
      Object.entries(params).forEach((param) => {
        if (
          param[0] &&
          typeof param[1] !== "undefined" &&
          param[1] !== null &&
          param[1].toString() &&
          param[0] !== "_token"
        ) {
          if (index) {
            query = query.concat("&");
          }
          query = query.concat(param[0] + "=" + param[1]);
          index++;
        }
      });
    }
    return query;
  }

  async fieldLoad(itemModel, $this = null, id = null, prefix = "") {
    return new Promise((resolve) => {
      id = id
        ? id
        : itemModel.vars.value && itemModel.vars.value.data
          ? itemModel.vars.value.data.id
          : "";

      if (id) {
        Object.entries(itemModel.vars.attr["data-ajax-load"]).forEach(
          (ajax) => {
            const target = ajax[0];
            const url = ajax[1] + "/" + prefix + id;
            apiConnection.get(url).then((data) => {
              if (target && $this) {
                $this.$emit("ajaxTargetLoad", {
                  choices: { ...data },
                  target: target,
                });
              } else {
                resolve(data);
              }
            });
          },
        );
      }
    });
  }

  processResponse(data, route, successMessage, models) {
    if (data && !data.hasError) {
      router.push(route);
      notificationManager.showNotification("success", successMessage);
    } else if (data.responses && data.responses[0].validationErrors) {
      notificationManager.showNotification(
        "error",
        i18n.global.t("signed_error"),
      );
      formValidator.processResponses(
        data.responses[0].validationErrors,
        models,
      );
    }
  }

  processReporting(data, route, successMessage) {
    let reports = [];
    if (
      data &&
      (!data.reporting || (data.reporting && !data.reporting.length))
    ) {
      router.push(route);
      notificationManager.showNotification("success", successMessage);
    } else if (data && data.reporting && data.reporting.length) {
      notificationManager.showNotification(
        "error",
        i18n.global.t("signed_error"),
      );
      Object.values(data.reporting).forEach((report) => {
        reports.push(this.processReport(report));
      });
    }
    return reports;
  }

  processReport(report) {
    return (
      i18n.global.tc("global.import.error", {
        line: report.reporting.line,
        column: report.reporting.column,
      }) +
      " " +
      report.reporting.log
    );
  }

  isInputType(model) {
    return (
      (this.isTextType(model) ||
        this.isNumberType(model) ||
        this.isIntegerType(model) ||
        this.isEmailType(model) ||
        this.isPasswordType(model)) &&
      model.vars.block_prefixes[2] !== "textarea" &&
      model.vars.block_prefixes[3] !== "zama_tiny_mce_type"
    );
  }

  isNumberType(model) {
    return model.vars.block_prefixes[1] === "number";
  }

  isTextType(model) {
    return model.vars.block_prefixes[1] === "text";
  }

  isSubmitType(model) {
    return model.vars ? model.vars.block_prefixes[1] === "submit" : false;
  }

  isIntegerType(model) {
    return model.vars.block_prefixes[1] === "integer";
  }

  isEmailType(model) {
    return model.vars.block_prefixes[1] === "email";
  }

  isPasswordType(model) {
    return model.vars.block_prefixes[1] === "password";
  }

  isAjaxSearch(model) {
    return model.vars && model.vars.attr && model.vars.attr["data-ajax-url"];
  }

  isAjaxLoad(model) {
    return (
      model.vars.attr &&
      model.vars.attr["data-ajax-load"] &&
      model.vars.attr["data-ajax-id"]
    );
  }

  isAccordion(model) {
    return model.vars.attr && model.vars.attr["data-checkbox-accordion"];
  }

  isPhoneType(model) {
    return model.vars.block_prefixes[1] === "phone_number";
  }

  isChoiceType(model) {
    return model.vars.block_prefixes[1] === "choice";
  }

  isTextareaType(model) {
    return (
      model.vars.block_prefixes[1] === "text" &&
      model.vars.block_prefixes[2] === "textarea" &&
      model.vars.block_prefixes[3] !== "zama_tiny_mce_type"
    );
  }

  isEditorType(model) {
    return (
      model.vars.block_prefixes[1] === "text" &&
      model.vars.block_prefixes[2] === "textarea" &&
      model.vars.block_prefixes[3] === "zama_tiny_mce_type"
    );
  }

  isDateType(model) {
    return (
      model.vars.block_prefixes[1] === "date" ||
      model.vars.block_prefixes[1] === "datetime" ||
      model.vars.block_prefixes[1] === "time"
    );
  }

  isTimeType(model) {
    return model.vars.block_prefixes[1] === "time";
  }

  isTimeRefType(model) {
    return (
      model.vars.block_prefixes[1] === "time" &&
      model.vars.attr &&
      (model.vars.attr["data-combine-hour"] ||
        model.vars.attr["data-combine-day"])
    );
  }

  isRadioType(model) {
    return (
      this.isChoiceType(model) &&
      model.vars.expanded &&
      !model.vars.multiple &&
      typeof model.vars.data !== "boolean"
    );
  }

  isSwitchType(model) {
    return (
      model.vars.block_prefixes[1] === "switch" ||
      (this.isChoiceType(model) &&
        typeof model.vars.data === "boolean" &&
        model.vars.attr &&
        !model.vars.attr["data-form-list"])
    );
  }

  isCheckboxType(model) {
    return (
      this.isChoiceType(model) &&
      model.vars.expanded &&
      model.vars.multiple &&
      typeof model.vars.data !== "boolean" &&
      ((model.vars.attr && !model.vars.attr["data-form-list"]) ||
        !model.vars.attr)
    );
  }

  isSelectType(model) {
    return (
      !model.vars.expanded &&
      this.isChoiceType(model) &&
      typeof model.vars.data !== "boolean" &&
      (!model.vars.attr ||
        (model.vars.attr &&
          !model.vars.attr["data-form-list"] &&
          !model.vars.attr["data-template-variables"]))
    );
  }

  isTemplateVariableType(model) {
    return model.vars.attr && model.vars.attr["data-template-variables"];
  }

  isEntityType(model) {
    return model.vars.block_prefixes[2] === "entity";
  }

  isMultiple(model) {
    return model.vars.multiple;
  }

  isFileType(model) {
    return (
      model.vars.block_prefixes[1] === "file" ||
      model.vars.block_prefixes[1] === "single_file_upload" ||
      model.vars.block_prefixes[1] === "file_upload"
    );
  }

  isListType(model) {
    return (
      this.isChoiceType(model) &&
      model.vars.attr &&
      model.vars.attr["data-form-list"]
    );
  }

  isRoleType(model) {
    return (
      model.vars.block_prefixes[1] === "collection" &&
      model.vars.block_prefixes[2] === "_role_permissions"
    );
  }

  isFormType(model) {
    return (
      model.vars.block_prefixes[1] === "user_address" ||
      model.vars.block_prefixes[1] === "planning_recurrence"
    );
  }

  processField(field, result, models, search = false) {
    if (
      this.isSelectType(field) &&
      !this.isMultiple(field) &&
      this.isEntityType(field)
    ) {
      this.processSelectEntity(field, result, search);
    } else if (
      this.isSelectType(field) &&
      !this.isEntityType(field) &&
      !this.isMultiple(field)
    ) {
      this.processSelect(field, result);
    } else if (this.isRadioType(field) || this.isSwitchType(field)) {
      this.processSwitchAndRadio(field, result);
    } else if (this.isCheckboxType(field)) {
      this.processCheckbox(field, result, search);
    } else if (this.isSelectType(field) && this.isMultiple(field)) {
      this.processSelectMultiple(field, result);
    } else if (this.isNumberType(field)) {
      this.processNumber(field, result);
    } else if (this.isFileType(field)) {
      this.processFile(field, result);
    } else if (this.isFormType(field)) {
      result = this.processSubForm(field, result);
    } else if (this.isTimeRefType(field)) {
      this.processDate(field, result, models);
    } else if (this.isTextType(field) || this.isTextareaType(field)) {
      this.processText(field, result);
    } else {
      result[field.vars.name] = field.vars.value ? field.vars.value : null;
    }

    return result;
  }

  processSelectEntity(field, result, search = false) {
    if (field.vars.value && field.vars.value.value) {
      if (search) {
        result[field.vars.name] = field.vars.value.value;
      } else {
        result[field.vars.name] = {
          id: field.vars.value.value,
        };
      }
    } else {
      result[field.vars.name] = null;
    }
  }

  processSelectMultiple(field, result) {
    result[field.vars.name] = [];
    Object.values(field.vars.value).forEach((value) => {
      result[field.vars.name].push(
        field.vars.block_prefixes[2] === "entity" &&
          typeof value.data === "object"
          ? { id: value.data.id }
          : value.data,
      );
    });
  }

  processSelect(field, result) {
    if (typeof field.vars.value === "boolean") {
      result[field.vars.name] = field.vars.value;
    } else {
      result[field.vars.name] = field.vars.value ? field.vars.value.data : null;
    }
  }

  processSwitchAndRadio(field, result) {
    let choice;
    if (field.vars.choices) {
      choice = field.vars.choices.find(
        (item) =>
          item.value ===
          (typeof field.vars.value !== "boolean"
            ? field.vars.value !== null
              ? field.vars.value.toString()
              : ""
            : field.vars.value),
      );
    }
    result[field.vars.name] =
      typeof field.vars.data === "boolean"
        ? !!field.vars.value
        : choice
          ? choice.data
          : null;
  }

  processCheckbox(field, result, search) {
    result[field.vars.name] = [];
    if (field.vars.value && Object.keys(field.vars.value).length) {
      Object.values(field.vars.value).forEach((value) => {
        if (value.choices) {
          Object.values(value.choices).forEach((choice) => {
            if (choice.checked) {
              result[field.vars.name].push(choice.data);
            }
          });
        } else {
          if (value.checked) {
            let data;
            if (
              field.vars.block_prefixes[2] === "entity" &&
              search &&
              value.data.id
            ) {
              data = value.data.id;
            } else {
              data = value.data;
            }
            result[field.vars.name].push(data);
          }
        }
      });
    }
  }

  processNumber(field, result) {
    result[field.vars.name] = parseFloat(field.vars.value);
  }

  processFile(field, result) {
    if (field.vars.value && field.vars.value.id) {
      result[field.vars.name] = { id: field.vars.value.id };
    } else {
      result[field.vars.name] = null;
    }
  }

  processSubForm(field, result) {
    if (
      field.vars.block_prefixes[1] === "user_address" ||
      field.vars.block_prefixes[1] === "planning_recurrence"
    ) {
      let subFormResult = this.processForm(field.children);
      if (field.vars.value && field.vars.value.id) {
        subFormResult = {
          ...subFormResult,
          id: parseInt(field.vars.value.id),
        };
      }
      if (field.vars.block_prefixes[1] === "planning_recurrence") {
        result = { ...result, planningRecurrence: subFormResult };
      } else if (field.vars.block_prefixes[1] === "user_address") {
        result = { ...result, personalAddress: subFormResult };
      }
    }
    return result;
  }

  processDate(field, result, models) {
    let ref = null;
    Object.values(models).forEach((model) => {
      if (
        !ref &&
        (!Object.keys(model.children).length ||
          model.vars.block_prefixes[1] === "time" ||
          model.vars.block_prefixes[1] === "date" ||
          model.vars.block_prefixes[1] === "datetime")
      ) {
        ref =
          model.vars.name === field.vars.attr["data-combine-day"] ||
          model.vars.name === field.vars.attr["data-combine-hour"]
            ? model
            : null;
      } else if (!ref) {
        ref = Object.values(model.children).find(
          (item) =>
            item.vars.name === field.vars.attr["data-combine-hour"] ||
            item.vars.name === field.vars.attr["data-combine-day"],
        );
      }
    });
    if (ref) {
      if (field.vars.attr["data-combine-day"]) {
        result[field.vars.name] =
          ref.vars.value.split(" ")[0] + " " + field.vars.value;
      } else if (field.vars.attr["data-combine-hour"]) {
        result[ref.vars.name] = ref.vars.value.concat(" " + field.vars.value);
      }
    }
  }

  processText(field, result) {
    if (
      field.vars.attr &&
      field.vars.attr["data-type-array"] &&
      typeof field.vars.value === "string"
    ) {
      result[field.vars.name] = field.vars.value
        ? field.vars.value.replace(/[;_\-\s:]/g, ",").split(",")
        : [];
    } else {
      result[field.vars.name] = field.vars.value ? field.vars.value : "";
    }
  }
}

export const formManager = new FormManager();
