export default {
  chooseFile: "Choisissez un fichier",
  browse: "Parcourir",
  clearFile: "Supprimer le fichier",
  requiredField: "Ce champ est obligatoire",
  requiredNumberOrFloat: "Merci de saisir un chiffre entier ou décimal",
  emailError: "Merci de saisir une adresse email conforme",
  phoneError: "Merci de saisir un numéro de téléphone conforme",
  lastName: "Nom",
  firstName: "Prénom",
  email: "Email",
  address: "Adresse",
  complement: "Complément",
  postCode: "Code postal",
  city: "Ville",
  phone: "Téléphone",
  mobile: "Mobile",
  actions: "Actions",
  rights: "Droits",
  send: "Envoyer",
  errors: "Attention, il y a des erreurs",
  form_validation: "Les champs suivants sont obligatoires :",
  entitled: "Intitulé",
  title: "Titre",
  selectValue: "Sélectionner une valeur",
  uploaded_on: "Mise en ligne le",
  error_file: "Oups, on dirait que le fichier est indisponible",
  download_success: "Fichier téléchargé avec succès !",
  file_downloading: "Téléchargement en cours...",
  download_file: "Télécharger le fichier",
  remove_file: "Retirer le fichier",
  signed_error: "Oups, il y'a eu une erreur lors de l'enregistrement",
  saving: "Enregistrement en cours",
  confirm: "Confirmer",
  import: "Importer",
  error_nofile: "Vous devez ajouter un fichier",
  error_file_format: "Veuillez ajouter un fichier au bon format !",
  error_date:
    "Veuillez renseigner une date de début antérieure à la date de fin",
  link: "Ouvrir le lien",
  dateChoice: "Cliquez pour choisir une date",
  next: "Suivant",
  pleaseWait: "Veuillez patienter ...",
  select: "Sélectionner",
  cancel: "Annuler",
  ok: "OK",
  edit: "Modifier",
  no: "Non",
  yes: "Oui",
  warning: "Attention",
  iConfirm: "Je confirme",
  Save: "Enregistrement",
  saveFinish: "L'enregistrement est terminé",
  fileFormatError: "Veuillez séléctionner un fichier au bon format",
  display: "Afficher",
  elements: "éléments",
  lines: "Lignes",
  pagination: {
    to: "à",
    on: "sur",
  },
  submit: "Envoyer",
  back: "Retour",
  remove: "Enlever",
  typeYourSearch: "Tapez votre recherche...",
  sortBy: "Trier par",
  order: "Ordre",
  desc: "Décroissant",
  asc: "Croissant",
  reset: "Réinitialiser",
  filter: "Filtrer",
  Search: "Rechercher",
  "No data available in the table": "Aucune donnée disponible dans le tableau",
  "No data available": "Aucune donnée disponible",
  "Please enter or modify your search":
    "Veuillez saisir ou modifier votre recherche",
  "Please click on the add button":
    "Veuillez cliquer sur le bouton pour en ajouter",
  search: "Rechercher",
  All: "Tous",
  None: "Aucun",
  "PDF export": "Export PDF",
  "Excel export": "Export excel",
  "PNG export": "Export PNG",
  "SVG export": "Export SVG",
  "See statistics": "Voir les statistiques",
  multiselect: {
    press_enter: "",
    press_enter_group: "",
    selected: "",
    press_enter_remove: "",
    press_enter_remove_group: "",
    press_enter_create_tag: "Ajouter une valeur",
    select_option: "",
    no_result: "Aucun résultat ne correspond à votre recherche",
    no_options: "Tapez au moins 2 caractères pour rechercher des résultats...",
  },
  init_search: "Réinitialiser la recherche",
  search_motor: "Moteur de recherche",
  file_size_error: "Veuillez choisir un fichier de moins de ",
  maxTagError: "Veuillez respecter le nombre maximum de valeur",
  minTagError: "Veuillez respecter le nombre minimum de valeur",
  see_more_fields: "Voir plus de champs",
  justificative_piece: "Pièces justificatives",
  skillRecap: "Bilan de compétences",
};
