import { defineStore } from "pinia";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";

export const useNotificationStore = defineStore({
  id: "Notification",
  state: () => {
    return {
      status: "",
      last: null,
      open: false,
      messages: [],
      list: [],
      listOpened: false,
    };
  },

  actions: {
    setStatus(status) {
      this.status = status;
    },
    setLast(notification) {
      this.notification = notification;
    },
    setOpen(open) {
      this.open = open;
      if (this.status === messengerMessageManager.STATUS_SUCCESS && open) {
        this.setStatus(messengerMessageManager.STATUS_OFF);
      }
    },
    addMessage(message) {
      this.messages.push(message);
    },
    setList(list) {
      this.list = list;
    },
    setListOpened(value) {
      this.listOpened = value;
    },
  },
});
